<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">已完成的领料单</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="xm" placeholder="请输入申请人姓名" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="CreatorName" label="申请人"></el-table-column>
                    <el-table-column prop="orderStr" label="领料清单"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="OutLogPost(scope.row)" type="text" size="small" style="color:#E6A23C">查看领料记录</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="领料记录查看" :visible.sync="logModal" width="1200px" :close-on-click-modal="false" top="30px">
            <el-table :data="logList" stripe style="width: 100%" :height="dialogMaxHeigh" > 
                <el-table-column prop="WAREHOUSENAME" label="仓库"></el-table-column>
                <el-table-column prop="GoodName" label="货名"></el-table-column>
                <el-table-column prop="GoodCount" label="出库数量"></el-table-column>
                <el-table-column prop="SAVECODE" label="出库货格"></el-table-column>
                <el-table-column prop="Target" label="出库去向"></el-table-column>
                <el-table-column prop="BZ" label="备注"></el-table-column>
            </el-table>
            <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "UnitManage",

  data() {
    




    return {
        sysType:"",

        dialogMaxHeigh:0,

        canDo:true,
        showPagination:false,
        xm:"",
        ckid:"",
        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,


        logModal:false,
        logList:[],

    };
  },
  mounted() {
    this.sysType=this.$store.state.sysType
    this.ckid=this.$store.state.ckid
    this.dialogMaxHeigh = h -300
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.zwm=getQueryData.name
        this.ywm=getQueryData.englishName
        this.dm=getQueryData.code
        this.ghs=getQueryData.supplier
    }
    this.loadItem=null
    this.getPostList()
    this.getRelayList()
  },
  methods: {
    storeModalClose(){
        this.nowGoods=null  
    },
    getRelayList(){
        this.$http.post("Relay/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.relayList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            userName:this.xm,
            status:"4",
            wareHouseSnowID:this.ckid,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("GoodList/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var nl=[]
                var list =response.data.DATA
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    d.orderStr=""
                    for(var j=0;j<d.GoodListDetailList.length;j++){
                        var o=d.GoodListDetailList[j]
                        if(j==0){
                            d.orderStr=o.GoodName+"×"+o.NeedCount
                        }else{
                            d.orderStr+="、"+o.GoodName+"×"+o.NeedCount
                        }
                    }
                    nl.push(d)
                }
                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },
    OutLogPost(item){
        this.showLoading("请稍后")
        var params={
            GoodListSnowID:item.SnowID,
            status:"4",
        }
        this.logList=[]
        this.$http.post("GoodIn/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.logList=response.data.DATA
                    this.logModal=true
                }
            })
            .catch((error) => {
                this.hideLoading()
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>

</style>