<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">待领料的领料单</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="xm" placeholder="请输入申请人姓名" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="CreatorName" label="申请人"></el-table-column>
                    <el-table-column prop="orderStr" label="领料清单"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="OutGoodsPost(scope.row)" type="text" size="small" style="color:#67C23A" v-if="sysType=='2'">领料出库</el-button>
                            <el-button @click="OutLogPost(scope.row)" type="text" size="small" style="color:#E6A23C">查看领料记录</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="领料出库" :visible.sync="orderModal" width="1200px" :close-on-click-modal="false" top="30px">
                <div class="goodsOutTH">
                    <div class="goodsOutItemP" style="width:200px">物料名称</div>
                    <div class="goodsOutItemP" style="text-align:center;">申请数量</div>
                    <div class="goodsOutItemP" style="text-align:center;">已出库数量</div>
                    <div class="goodsOutItemP" style="text-align:center;">累计出库金额</div>
                    <div class="goodsOutItemP" style="width:540px">本次出库选择</div>
                </div>
                <div class="goodsOutItem" v-for="item in orderList" :key="item.GoodSnowID">
                    <div class="goodsOutItemP" style="width:200px">{{item.GoodName}}</div>
                    <div class="goodsOutItemP" style="text-align:center;">{{item.NeedCount}}</div>
                    <div class="goodsOutItemP" :style="{ color: item.flag ? '#F56C6C' : '#409EFF' }" style="text-align:center;">{{item.OutCount}}</div>
                    <div class="goodsOutItemP" :style="{ color: item.flag ? '#F56C6C' : '#409EFF' }" style="text-align:center;">{{item.OutMoney}}</div>
                    <div class="goodsOutItemP" style="width:540px" v-if="findGoodsOk">
                        <div v-if="item.ready" class="goodsSourceListOut">
                            <div v-if="item.GoodsSource.length>0" style="margin-top:5px;">
                                <div class="goodsSourceList" v-for="(source,sourceindex) in item.GoodsSource" :key="sourceindex">
                                    
                                    <p class="positionInfoStr">位置：{{source.ShelvingCode}}货架{{source.ShelvingRowIndex}}行{{source.ShelvingColumnIndex}}列{{source.Index}}货格({{source.ShelvingCode}}-{{source.ShelvingRowIndex}}-{{source.ShelvingColumnIndex}}-{{source.Index}})　库存：{{source.GoodCount}}</p>
                                    <el-input-number class="goodsCheckCount" size="mini" v-model="source.check" :min="0"  :max="source.GoodCount" @change="quantityChangeFn">
                                    </el-input-number>
                                    

                                </div>
                            </div>
                           <div v-else class="goodsEmpty">
                                未在仓库中查找到此物料，请先入库
                           </div>
                        </div>
                        <div v-else class="goodsPositionLoading">
                            物料搜寻中，请稍后...
                        </div>
                    </div>
                </div>
                <div style="height:20px;width:100%;float:left;"></div>
                <div style="width:100%;overflow:hidden;">
                    出库方向：<el-input v-model="orderTarget" placeholder="请输入出库方向" style="width:43%;"></el-input>
                    　备注：<el-input v-model="orderBz" placeholder="请输入出库备注"  style="width:43%;"></el-input>
                </div> 
                <div style="width:100%;overflow:hidden;margin-top:20px;text-align:right;">领料清单：{{}}</div>
                <div style="width:100%;overflow:hidden;margin-top:20px;">
                    <el-button type="success" @click="sendOut">确认出库</el-button>
                    <el-button type="danger" @click="closeLight" class="fr" v-if="relayList.length>0">关闭全部指示灯</el-button>
                    <el-button type="primary" @click="openLight" class="fr" v-if="relayList.length>0" style="margin-right:10px;">开启货架指示灯</el-button>
                  
                </div> 
               
        </el-dialog>
        <el-dialog title="领料出库" :visible.sync="outModal" width="1200px" :close-on-click-modal="false" top="30px">
                <div style="width:100%;overflow:hidden;">
                    <p style="float:left;font-size:14px;margin-top:5px;">领料清单：{{orderGoodsStr}}</p>
                    <el-button type="danger" @click="closeLight" class="fr" size="mini" v-if="relayList.length>0">关闭全部指示灯</el-button> 
                    <el-button type="primary" @click="openLight" class="fr" size="mini" v-if="relayList.length>0" style="margin-right:10px;">开启货架指示灯</el-button>
                </div>
                <div style="width:100%;overflow:hidden;margin-top:20px;">
                    物料编号：<el-input v-model="outGoodsCode" placeholder="请输入或使用扫码枪扫描物料编号" style="width:1010px;margin-right:10px;" ref="outGoodsCode" @keyup.enter.native="searchOutGoodsItemByCode"></el-input>
                    <el-button type="primary" @click="searchOutGoodsItemByCode">查询</el-button>
                </div> 
                <div style="overflow:hidden;background-color:#F8F8F8;margin:20px 0;padding:10px 12px;">
                    <div class="goodsOutTH">
                        <div class="goodsOutItemP" style="width:200px">物料名称</div>
                        <div class="goodsOutItemP" style="text-align:center;">申请数量</div>
                        <div class="goodsOutItemP" style="text-align:center;">已出库数量</div>
                        <div class="goodsOutItemP" style="text-align:center;">累计出库金额</div>
                        <div class="goodsOutItemP" style="width:540px">本次出库选择</div>
                    </div>
                    <div v-if="orderList.length==0" class="orderListNull">
                        请输入或扫描物料编号添加出库物料
                    </div>
                    <div class="goodsOutItem" v-for="item in orderList" :key="item.GoodSnowID">
                        <div class="goodsOutItemP" style="width:200px">{{item.GoodName}}</div>
                        <div class="goodsOutItemP" style="text-align:center;">{{item.NeedCount}}</div>
                        <div class="goodsOutItemP" :style="{ color: item.flag ? '#F56C6C' : '#409EFF' }" style="text-align:center;">{{item.OutCount}}</div>
                        <div class="goodsOutItemP" :style="{ color: item.flag ? '#F56C6C' : '#409EFF' }" style="text-align:center;">{{item.Money}}</div>
                        <div class="goodsOutItemP" style="width:500px">
                            <div class="goodsSourceListOut">
                                <div style="margin-top:5px;">
                                    <div class="goodsSourceList" v-for="(source,sourceindex) in item.GoodsSource" :key="sourceindex">
                                        <p class="positionInfoStr">位置：{{source.ShelvingCode}}货架{{source.ShelvingRowIndex}}行{{source.ShelvingColumnIndex}}列{{source.Index}}货格({{source.ShelvingCode}}-{{source.ShelvingRowIndex}}-{{source.ShelvingColumnIndex}}-{{source.Index}})　库存：{{source.GoodCount}}</p>
                                        <el-input-number class="goodsCheckCount" size="mini" v-model="source.check" :min="0"  :max="source.GoodCount" @change="quantityChangeFn">
                                        </el-input-number>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div>
                </div>     
                <div style="height:20px;width:100%;float:left;"></div>
                <div style="width:100%;overflow:hidden;">
                    出库方向：<el-input v-model="orderTarget" placeholder="请输入出库方向" style="width:514px;"></el-input>
                    　备注：<el-input v-model="orderBz" placeholder="请输入出库备注"  style="width:514px;"></el-input>
                </div> 
                <div style="width:100%;overflow:hidden;margin-top:20px;" >
                    <el-button type="success" @click="sendOut"  class="fr">确认出库</el-button>
                    
                </div> 
               
        </el-dialog>
        <el-dialog title="领料记录查看" :visible.sync="logModal" width="1200px" :close-on-click-modal="false" top="30px">
            <el-table :data="logList" stripe style="width: 100%" :height="dialogMaxHeigh" > 
                <el-table-column prop="WAREHOUSENAME" label="仓库"></el-table-column>
                <el-table-column prop="GoodName" label="货名"></el-table-column>
                <el-table-column prop="GoodCount" label="出库数量"></el-table-column>
                <el-table-column prop="SAVECODE" label="出库货格"></el-table-column>
                <el-table-column prop="Target" label="出库去向"></el-table-column>
                <el-table-column prop="BZ" label="备注"></el-table-column>
                <el-table-column prop="CreateTime" label="时间"></el-table-column>
            </el-table>
            <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "UnitManage",

  data() {
    

    var count= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };


    return {
        sysType:"",

        dialogMaxHeigh:0,

        canDo:true,
        showPagination:false,
        xm:"",
        ckid:"",
        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,

        orderBz:"",
        orderTarget:"",
        orderInfo:null,
       
        orderModal:false,
        orderList:[],
        
        relayList:[],

        findGoodsOk:false,
        logModal:false,
        logList:[],

        orderGoodsStr:"",
        orderGoodsLight:{},
        orderGoodsLightCount:0,
        outModal:false,
        //outList:[],
        outGoodsCode:"",
        outGoodsItem:null,

    };
  },
  mounted() {
    this.sysType=this.$store.state.sysType
    this.ckid=this.$store.state.ckid
    this.dialogMaxHeigh = h -300
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.zwm=getQueryData.name
        this.ywm=getQueryData.englishName
        this.dm=getQueryData.code
        this.ghs=getQueryData.supplier
    }
    this.loadItem=null
    this.getPostList()
    this.getRelayList()
  },
  methods: {
    storeModalClose(){
        this.nowOrder=null  
    },
    getRelayList(){
        this.$http.post("Relay/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.relayList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            userName:this.xm,
            status:"3",
            wareHouseSnowID:this.ckid,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("GoodList/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var nl=[]
                var list =response.data.DATA
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    d.orderStr=""
                    for(var j=0;j<d.GoodListDetailList.length;j++){
                        var o=d.GoodListDetailList[j]
                        if(o.IsOrigin==1){
                            if(d.orderStr==""){
                                d.orderStr=o.GoodName+"×"+o.NeedCount
                            }else{
                                d.orderStr+="、"+o.GoodName+"×"+o.NeedCount
                            }
                        }
                        
                    }
                    nl.push(d)
                }
                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },
    OutPost(item){
        if(item.GoodListDetailList.length==0){
            this.$message.error("暂无领料清单")
        }else{
            this.findGoodsOk=false
            this.orderInfo=item
            var list=item.GoodListDetailList
            var nl=[]
            for(var i=0;i<list.length;i++){
                var d=list[i]
                var oc=0
                var om=0
                if(d.OutCount){
                    oc=d.OutCount
                }
                if(d.Money){
                    om=d.Money
                }
                d.OutCount=oc
                d.OutMoney=om
                d.GoodsSource=[]
                d.ready=false
                d.flag=false
                if(oc>d.NeedCount){
                    d.flag=true
                }
                nl.push(d)
                this.findGoods(item.WareHouseSnowID,d.GoodSnowID,i)
            }
            this.orderBz=""
            this.orderTarget=""
            this.orderList=nl
            this.orderModal=true
        }
    },
    findGoods(storeId,goodsId,goodsIndex){
        var params={
            WareHouseSnowID:storeId,
            goodSnowID:goodsId,
        }
        var nl=[]

        this.setQueryData(this.$route.name,params)
        this.$http.post("ColumnGrid/GetDataList", params)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var goodsps =response.data.DATA
                    //var goodsps=[]
                    // for(var i=0;i<list.length;i++){
                    //     var  d=list[i]
                    //     d.check=0
                    //     goodsps.push(d)
                    // }
                    goodsps.map((rowa) => {
                        rowa.check=0
                        return rowa
                        //this.$set(rowa, 'check', 0);
                    })
                    // this.orderList.map((rowb, m) => {
                    //     if(m==goodsIndex){
                    //         this.$set(rowb, 'GoodsSource', goodsps);
                    //         this.$set(rowb, 'ready', true);
                    //     }
                        
                    // })
                    // console.log(this.orderList)
                    for(var j=0;j<this.orderList.length;j++){
                        var oj=this.orderList[j]
                        if(j==goodsIndex){
                            oj.GoodsSource=goodsps
                            oj.ready=true
                        }
                        nl.push(oj)
                    }
                    this.orderList=nl
                    if(goodsIndex==this.orderInfo.GoodListDetailList.length -1){
                        this.findGoodsOk=true
                    }
                    
                } 
            })
    },

    //  确认出库
    sendOut(){
        var outList=[]
        var hasOutMemu=false
        var withoutMemuStr=""
        for(var i=0;i<this.orderList.length;i++){
            for(var j=0;j<this.orderList[i].GoodsSource.length;j++){
                var od=this.orderList[i].GoodsSource[j]
                if(od.check>0){
                    var code=od.ShelvingCode+"-"+od.ShelvingRowIndex+"-"+od.ShelvingColumnIndex+"-"+od.Index
                    var ouItem={
                        shelvingSnowID:od.ShelvingSnowID,
                        gridCode:code,
                        goodCount:od.check,
                        goodListSnowID:this.orderInfo.SnowID,
                        //goodListDetailSNowID:this.orderList[i].SnowID,
                        bz:this.orderBz,
                        target:this.orderTarget,
                    }
                    outList.push(ouItem)
                    if(this.orderList[i].SnowID =="" || this.orderList[i].IsOrigin ==0){
                        hasOutMemu=true
                        if(withoutMemuStr==""){
                            withoutMemuStr=this.orderList[i].GoodName
                        }else{
                            withoutMemuStr+="、"+this.orderList[i].GoodName
                        }
                    }
                }
            }
        }
        if(outList.length==0){
            this.$message.error("请选择要出库的商品数量")
        }else{
            if(hasOutMemu){
                this.$confirm(withoutMemuStr+'不在领料申请单中，是否仍要出库？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.showLoading("请稍后")
                    for(var k=0;k<outList.length;k++){
                        var finish=false
                        if(k== (outList.length-1)){
                            finish=true
                        }
                        //console.log(outList[k])
                        this.sendOutOrder(outList[k],finish)
                    }
                })
            }else{
                this.showLoading("请稍后")
                for(var k=0;k<outList.length;k++){
                    var finishB=false
                    if(k== (outList.length-1)){
                        finishB=true
                    }
                    //console.log(outList[k])
                    this.sendOutOrder(outList[k],finishB)
                }
            }

            
        }
    },
    sendOutOrder(params,finish){
        this.$http.post("GoodIn/OutWareHouse", params)
        if(finish){
            this.hideLoading()
            this.$message.success("已出库")
            this.orderModal=false
            this.getPostList()
        }
    },

    openLight(){
        if(this.orderGoodsLightCount>0){
            this.setLightCommands(this.orderGoodsLight)
        }else{
            this.$message.error("暂无可亮的指示灯！")
        }
    },
    setLightCommands(obj){
        var commands=[]
        //遍历继电器
        for(var j=0;j<this.relayList.length;j++){
            let litem=this.relayList[j]
            let command='zq '+litem.Address+' set'
            let objList=[]
            if(obj[litem.Address] != undefined && obj[litem.Address].length>0){
                objList=obj[litem.Address]
            }
            //遍历继电器的每个灯
            for(var k=1;k<=litem.LightCount;k++){
                let m=' 0'
                //判断灯的开启状态
                for(var h=0;h<objList.length;h++){
                    if(objList[h] == k){
                        m=' 1'
                        break;
                    }
                }
                command+=m
            }
            command+=' qz'
            let citem={
                RelayAddr:litem.Address,
                Command:command,
            }
            commands.push(citem)
        }
        this.openRelayLights(commands)
    },
    closeLight(){
        this.$confirm('确定关闭所有指示灯？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.setLightCommands({})
        })
    },
    openRelayLights(list){
        this.$http.post("Relay/SendCommand", list)
            .then((response) => {
                this.$message.success("命令发送完成！")
            })
            .catch((error) => {
                this.$message.error("请求出错")
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },
    getRelayIdByAddress(address){
        var rid=""
        for(var i=0;i<this.relayList.length;i++){
            if(this.relayList[i].Address == address){
                rid = this.relayList[i].SnowID
            }
        }
        return rid
    },
    OutLogPost(item){
        this.showLoading("请稍后")
        var params={
            GoodListSnowID:item.SnowID,
            status:"4",
        }
        this.logList=[]
        this.$http.post("GoodIn/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.logList=response.data.DATA
                    this.logModal=true
                }
            })
            .catch((error) => {
                this.hideLoading()
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },

    quantityChangeFn() {
      this.$forceUpdate()
    },

    OutGoodsPost(item){
        this.orderInfo=item
        this.orderGoodsLight={}
        this.orderGoodsLightCount=0
        var str=""
        for(var i=0;i<item.GoodListDetailList.length;i++){
            var gd=item.GoodListDetailList[i]
            if(gd.IsOrigin ==1){
                if(str==""){
                    str=gd.GoodName+"×"+gd.NeedCount
                }else{
                    str+="、"+gd.GoodName+"×"+gd.NeedCount
                }
                this.getGoodsPosition(gd.GoodSnowID)
            }
        }
        this.orderGoodsStr=str
        this.orderList=[]
        this.outGoodsItem=null
        this.outModal=true
        this.resetGoodsCodeInput()
        
    },
    getGoodsPosition(goodsid){
        this.showLoading("请稍后")
        var params={
            goodSnowID:goodsid,
            WareHouseSnowID:this.ckid,
        }
        this.$http.post("ColumnGrid/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        if(this.orderGoodsLight[d.Address] == undefined){
                            this.orderGoodsLight[d.Address] = []
                        }
                        this.orderGoodsLight[d.Address].push(d.RelayNO)
                        this.orderGoodsLightCount++
                    }
                } 
            })
    },
    resetGoodsCodeInput(){
        this.outGoodsCode=""
        setTimeout(() => {
            this.$refs.outGoodsCode.focus()   
        }, 200);
    },
    searchOutGoodsItemByCode(){
        this.showLoading("请稍后")
        var params={
            pageIndex:1,
            pageSize:1,
            AllCode:this.outGoodsCode,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Good/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                this.findGoodsByGoodsItem(response.data.DATA[0])
            } else {
                this.$message.error("未查询到此编号对应物料")
                this.resetGoodsCodeInput()
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.$message.error("未查询到此编号对应物料")
                this.resetGoodsCodeInput()
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    findGoodsByGoodsItem(item){
        var flag=this.flagGoodsInOutList(item)
        if(!flag){
          
            var params={
                WareHouseSnowID:this.orderInfo.WareHouseSnowID,
                goodSnowID:item.SnowID,
            }
            this.setQueryData(this.$route.name,params)
            this.$http.post("ColumnGrid/GetDataList", params)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var glist=response.data.DATA
                    glist.map((row,index) => {
                        row.check=0
                        if(index==0){
                            row.check=1
                        }
                        return row
                    })
                    var gitem=this.getOutListGoodsItem(item)
                    gitem.GoodsSource=glist
                    this.orderList.push(gitem)
                }else{
                    this.$message.error(item.Name+"已无库存，请先入库！")
                    this.resetGoodsCodeInput()
                }
            })
            .catch((error) => {
                this.$message.error(item.Name+"已无库存，请先入库！")
                this.resetGoodsCodeInput()
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
        }else{
            var nl=[]
            for(var i=0;i<this.orderList.length;i++){
                var outItem=this.orderList[i]
                if(outItem.GoodSnowID == item.SnowID){
                    var gsList=[]
                    var addFlag=false
                    for(var j=0;j<outItem.GoodsSource.length;j++){
                        var gsItem=outItem.GoodsSource[j]
                        if(!addFlag && gsItem.check<gsItem.GoodCount){
                            gsItem.check=gsItem.check+1
                            addFlag=true
                        }
                        gsList.push(gsItem)
                    }
                    outItem.GoodsSource=gsList
                }
                nl.push(outItem)
            }
            this.orderList=nl
        }
    },
    flagGoodsInOutList(item){
        var f=false
        var nl=[]
        for(var i=0;i<this.orderList.length;i++){
            var d=this.orderList[i]
            if(d.GoodSnowID == item.SnowID){
                f=true
            }
            nl.push(d)
        }
        this.orderList=nl
        return f
    },
    getOutListGoodsItem(item){
        var gitem={
            GoodSnowID:item.SnowID,
            GoodName:item.Name,
            IsOrigin:0,
            NeedCount:0,
            OutCount:0,
            Money:0,
            GoodsSource:[],
            SnowID:"",
        }
        var list =this.orderInfo.GoodListDetailList
        for(var i=0;i<list.length;i++){
            var d=list[i]
            if(d.GoodSnowID == item.SnowID){
                gitem.IsOrigin=d.IsOrigin
                gitem.SnowID=d.SnowID
                if(d.OutCount){
                    gitem.OutCount=d.OutCount
                }
                if(d.NeedCount){
                    gitem.NeedCount=d.NeedCount
                }
                if(d.Money){
                    gitem.Money=d.Money
                }
                break;
            }
        }
        console.log(gitem)
        return gitem
    }

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.goodsOutTH{height:40px;overflow:hidden;border-bottom: 1px solid #333333;}
.goodsOutItemP{width: 100px;font-size: 14px;line-height: 40px;padding: 0 12px;float:left;}
.goodsSourceListOut{overflow: hidden;padding: 2px 10px;background-color: #F8F8F8;border-radius: 3px;line-height: 40px;}
.goodsSourceList{overflow: hidden;border-bottom: 1px solid #333333;}
.goodsSourceList:last-of-type{border-bottom: 0!important;}
.goodsSourceList .positionInfoStr{float:left;font-size: 12px;line-height: 30px;}
.goodsSourceList .goodsCheckCount{float:left;margin-left: 10px;}
.goodsOutTH .goodsOutItemP{font-weight: bold;}
.checkError{font-size: 12px;margin: 5px 0;color: #F56C6C;}
.goodsPositionLoading,.goodsEmpty{height: 40px;text-align: center;color: #ADADAD;font-size: 12px}
.goodsOutItem{overflow: hidden;border-bottom: 1px solid #ADADAD;}
.orderListNull{height:70px;font-size:12px;color:#ADADAD;line-height:80px;text-align:center;}
</style>