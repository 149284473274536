<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">继电器管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入继电器名称" class="iw" ></el-input>
                <el-select v-model="ckid" placeholder="选择仓库"  filterable  clearable class="iw" :disabled="sysType=='2'">
                        <el-option v-for="item in storeList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="WareHouseName" label="仓库名称"></el-table-column>
                    <el-table-column prop="Name" label="继电器名称"></el-table-column>
                    <el-table-column prop="Address" label="连接地址"></el-table-column>
                    <el-table-column prop="LightCount" label="指示灯数量"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="setPost(scope.row)" type="text" size="small" style="color:#67C23A">指示灯绑定</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="仓库" prop="wareHouseSnowID">
                    <el-select v-model="postInfo.wareHouseSnowID" placeholder="请选择仓库"  filterable clearable style="width:100%;" :disabled="sysType=='2'">
                        <el-option v-for="item in storeList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="连接地址" prop="address">
                    <el-input v-model="postInfo.address" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="指示灯数量">
                    <el-radio v-model="postInfo.lightCount" label="16">16</el-radio>
                    <el-radio v-model="postInfo.lightCount" label="32">32</el-radio>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="继电器指示灯设置" :visible.sync="lightModal" width="1200px" :close-on-click-modal="false" top="20px">
           <div v-for="(item,index) in lightArray" class="lightSetRow" :key="index" :class="item.lightInfo?'lightSet':''">
                <div class="top">
                    <p class="indexNo">{{index+1}}号指示灯</p>
                    <el-switch v-model="item.open" active-color="#13ce66" inactive-color="#ff4949" class="fr" size="mini" style="margin-top:2px;margin-left:10px;" @change="lightSwitch(index)"> </el-switch>
                    <div v-if="item.send" class="fr" style="font-size:12px;color:#ADADAD;margin-top:2px;">
                        提交中...
                    </div>
                    <div v-else class="fr">
                        <el-button type="text" size="mini" @click="editLight(index)" v-if="item.lightInfo"  style="color:#E6A23C;margin-top:-2px;">编辑</el-button>
                        <el-button type="text" size="mini" @click="delLight(index)" v-if="item.lightInfo"  style="color:#F56C6C;margin-top:-2px;">删除</el-button>
                        <el-button type="text" size="mini" @click="addLight(index)" v-else class="fr" style="color:#67C23A;margin-top:-2px;">添加</el-button>
                    </div>
                </div>
                <div class="inputRow">
                    <el-select v-model="item.rackId" filterable clearable style="width:90px;margin:0px;" @change="rackChange(index)" size="mini" class="signItem">
                        <el-option v-for="rack in rackList" :key="rack.SnowID" :label="rack.Code" :value="rack.SnowID" ></el-option>
                    </el-select>
                    <span style="margin:0 4px;">货架　</span>
                   
                    <el-input-number v-model="item.rowValue" controls-position="right"  :precision="0" :step="1" :min="1" :max="item.rowCount" style="width:80px;margin:0px;" size="mini" class="signItem"></el-input-number>
                    <span style="margin:0 4px;">行　</span>
                    
                    <el-input-number v-model="item.colValue" controls-position="right"  :precision="0" :step="1" :min="1" :max="item.colCount" style="width:80px;margin:0px;" size="mini" class="signItem"></el-input-number>
                    <span style="margin:0 4px;">列　</span>
                </div>
           </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
  name: "UnitManage",

  data() {
    var name = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入继电器名称"));
      } else {
        callback();
      }
    };
    var wareHouseSnowID = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择仓库"));
      } else {
        callback();
      }
    };
    var address = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入连接地址"));
      } else {
        callback();
      }
    };
    return {
        sysType:"",
        ckid:"",

        storeList:[],
        canDo:true,
        showPagination:false,
        name:"",

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            name: [{ validator: name, trigger: "blur" }],
            wareHouseSnowID: [{ validator: wareHouseSnowID, trigger: "blur" }],
            address: [{ validator: address, trigger: "blur" }],
        },


        rackList:[],
        lightItem:null,
        lightModal:false,
        lightArray:[],
    };
  },
  mounted() {
    this.sysType=this.$store.state.sysType  
    this.ckid=this.$store.state.ckid
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
    }
    this.loadItem=null
    this.getStoreList()
    this.getPostList()
  },
  methods: {
    getStoreList(){
        this.$http.post("WareHouse/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.storeList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.name,
            wareHouseSnowID:this.ckid,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Relay/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

    addPost(){
        this.postInfo={
            name:"",
            wareHouseSnowID:this.ckid,
            address:"",
            lightCount:'32',
        }
        this.doTypeStr="添加继电器"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Relay/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    editPost(item){
        this.postInfo={
            snowID:item.SnowID,
            name:item.Name,
            wareHouseSnowID:item.WareHouseSnowID,
            address:item.Address,
            lightCount:item.LightCount.toString(),
        }
        this.doTypeStr="修改继电器"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Relay/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.postInfo.lightCount=parseInt(this.postInfo.lightCount)
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除继电器：'+item.Name+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Relay/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },
    setPost(item){
        this.canDo=false
        this.showLoading("请稍后")
        this.lightItem=item
        this.lightArray=[]
        this.getRackList()
    },
    getRackList(storeid){
        this.$http.post("Shelving/GetDataList", {wareHouseSnowID:this.lightItem.WareHouseSnowID})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.rackList=response.data.DATA
                    var lightObject={}
                    for(var i=0;i<this.lightItem.LightCount;i++){
                        var litem={
                            lightInfo:null,
                            rackId:"",
                            rackInfo:null,
                            rowCount:0,
                            rowValue:0,
                            colCount:0,
                            colValue:0,
                            open:false,
                            send:false,
                        }
                        lightObject[i]=litem
                    } 
                    this.getLightSet(lightObject)
                }
            })
    },
    getLightSet(obj){
        this.$http.post("Light/GetDataList", {relaySnowID:this.lightItem.SnowID})
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        var num=d.RelayNO-1
                        obj[num].lightInfo=d
                        obj[num].rowValue=d.RowIndex
                        obj[num].colValue=d.ColumnIndex
                        var rackItem=this.getRackItemById(d.ShelvingSnowID,d.RowIndex)
                        obj[num].rackId=d.ShelvingSnowID
                        obj[num].rackInfo=rackItem.info
                        obj[num].rowCount=rackItem.rowCount
                        obj[num].colCount=rackItem.colCount
                    } 

                    for(var item in obj){
                        this.lightArray.push(obj[item])
                    }
                    this.hideLoading()
                    this.lightModal=true
                }
            })
    },
    getRackItemById(id,rowValue){
        var item={
            info:null,
            rowCount:0,
            colCount:0,
        }
        for(var i=0;i<this.rackList.length;i++){
            if(this.rackList[i].SnowID == id){
                
                item.info=this.rackList[i]
                var rackInfo=JSON.parse(item.info.Info)
                item.rowCount=rackInfo.length
                item.colCount=rackInfo[rowValue].length
                break;
            }
        }
        return item
    },
    rackChange(index){
        var nl=[]
        var d=this.lightArray[index]
        d.rackInfo=null
        d.rowCount=0
        d.rowValue=1
        d.colCount=0
        d.colValue=1
        for(var i=0;i<this.rackList.length;i++){
            if(this.rackList[i].SnowID == d.rackId){
                d.rackInfo=JSON.parse(this.rackList[i].Info)
                d.rowCount=d.rackInfo.length
                d.rowValue=1
                d.colCount=d.rackInfo[0].length
                d.colValue=1
                break;
            }
        }
        for(var j=0;j<this.lightArray.length;j++){
            if(j==index){
                nl.push(d)
            }else{
                nl.push(this.lightArray[j])
            }
        }

        this.lightArray = nl
    },
    addLight(index){
        var d=this.lightArray[index]
        if(!d.send){
            if(d.rackId==""){
                this.$message.error("请选择货架")
            }else{
                d.send=true
                var params={
                    relaySnowID:this.lightItem.SnowID,
                    shelvingSnowID:d.rackId,
                    rowIndex:d.rowValue,
                    columnIndex:d.colValue,
                    relayNO:(index+1),
                }
                this.$http.post("Light/InsertData", params)
                    .then((response) => {
                        if (response.data.SUCCESS) {
                            this.updateLightArrarItem(response.data.DATA,index)
                        }else{
                            this.$message.error(response.data.MSG)
                            d.send=false
                        }
                    })
                    .catch((error) => {
                        d.send=false
                        this.$message.error("请求出错")
                        if (error.isAxiosError) {
                            if(error.response.status==401){
                                this.logout()
                            }
                        }
                    });
            }
        }
    },
    updateLightArrarItem(id,index){
        this.$http.post("Light/GetDataList", {snowID:id})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var d=response.data.DATA[0]
                    this.lightArray[index].lightInfo=d
                    this.lightArray[index].send=false
                }
            })
    },
    editLight(index){
        var d=this.lightArray[index]
        console.log(d)
        if(!d.send){
            if(d.rackId==""){
                this.$message.error("请选择货架")
            }else{
                d.send=true
                var params={
                    snowID:d.lightInfo.SnowID,
                    relaySnowID:this.lightItem.SnowID,
                    shelvingSnowID:d.rackId,
                    rowIndex:d.rowValue,
                    columnIndex:d.colValue,
                    relayNO:(index+1),
                }
                this.$http.post("Light/UpdateData", params)
                    .then((response) => {
                        if (response.data.SUCCESS) {
                            this.updateLightArrarItem(params.snowID,index)
                        }else{
                            this.$message.error(response.data.MSG)
                            d.send=false
                        }
                    })
                    .catch((error) => {
                        d.send=false
                        this.$message.error("请求出错")
                        if (error.isAxiosError) {
                            if(error.response.status==401){
                                this.logout()
                            }
                        }
                    });
            }
        }
    },
    delLight(index){
        var d=this.lightArray[index]
        if(!d.send){
            this.$confirm('确定删除继电器：'+this.lightItem.Name+'的'+(index+1)+'号指示灯配置吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDelLight(index)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDelLight(index){
        var d=this.lightArray[index]
        d.send=true
        this.$http.post("Light/InsertData", {snowid:d.lightInfo.SnowID})
            .then((response) => {
                if (response.data.SUCCESS) {
                    d.lightInfo=null
                }else{
                    this.$message.error(response.data.MSG)
                }
                d.send=false
            })
            .catch((error) => {
                d.send=false
                this.$message.error("请求出错")
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                }
            });
    },
    lightSwitch(index){
        // var d=this.lightArray[index]
        // var open='open'
        // if(!d.open){
        //     open='close' 
        // }
        // this.openRelayLights(this.lightItem.Address,[index],open) 
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.lightSetRow{overflow: hidden;margin-bottom: 10px;width: 30%;float:left;margin-right: 1%;border: 1px solid #ADADAD;border-radius: 3px;padding:2px 10px 6px 10px;}
.lightSetRow .top{overflow: hidden;height:24px;}
.lightSetRow .indexNo{font-size: 12px;color: #444444;float:left;margin-top:4px;}
.lightSetRow .inputRow{overflow: hidden;float:left;font-size:12px;}
.lightSetRow .inputRow .signItem{margin-left: 12px}
.lightSet{border: 1px solid #409EFF!important;}
.lightSet .indexNo{color: #409EFF!important}

</style>