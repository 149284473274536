var  comjs = {
  baseUrl:'https://ckxt.17fbfb.com/api/',
  lightAllClose:"00 01 00 00 00 06 01 06 00 34 00 00",
  lightAllOpen:"00 01 00 00 00 06 01 06 00 34 00 01",
  lightInstruct:[
    {
      open:"00 01 00 00 00 06 01 06 00 00 00 01",
      close:"00 01 00 00 00 06 01 06 00 00 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 01 0A 00 01",
      close:"00 01 00 00 00 06 01 06 01 0A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 02 0A 00 01",
      close:"00 01 00 00 00 06 01 06 02 0A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 03 0A 00 01",
      close:"00 01 00 00 00 06 01 06 03 0A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 04 0A 00 01",
      close:"00 01 00 00 00 06 01 06 04 0A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 05 0A 00 01",
      close:"00 01 00 00 00 06 01 06 05 0A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 06 0A 00 01",
      close:"00 01 00 00 00 06 01 06 06 0A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 07 0A 00 01",
      close:"00 01 00 00 00 06 01 06 07 0A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 08 0A 00 01",
      close:"00 01 00 00 00 06 01 06 08 0A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 09 0A 00 01",
      close:"00 01 00 00 00 06 01 06 09 0A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 0A 00 01",
      close:"00 01 00 00 00 06 01 06 00 0A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 0B 00 01",
      close:"00 01 00 00 00 06 01 06 00 0B 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 0C 00 01",
      close:"00 01 00 00 00 06 01 06 00 0C 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 0D 00 01",
      close:"00 01 00 00 00 06 01 06 00 0D 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 0E 00 01",
      close:"00 01 00 00 00 06 01 06 00 0E 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 0F 00 01",
      close:"00 01 00 00 00 06 01 06 00 0F 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 10 00 01",
      close:"00 01 00 00 00 06 01 06 00 10 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 11 00 01",
      close:"00 01 00 00 00 06 01 06 00 11 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 12 00 01",
      close:"00 01 00 00 00 06 01 06 00 12 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 13 00 01",
      close:"00 01 00 00 00 06 01 06 00 13 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 14 00 01",
      close:"00 01 00 00 00 06 01 06 00 14 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 15 00 01",
      close:"00 01 00 00 00 06 01 06 00 15 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 16 00 01",
      close:"00 01 00 00 00 06 01 06 00 16 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 17 00 01",
      close:"00 01 00 00 00 06 01 06 00 17 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 18 00 01",
      close:"00 01 00 00 00 06 01 06 00 18 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 19 00 01",
      close:"00 01 00 00 00 06 01 06 00 19 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 1A 00 01",
      close:"00 01 00 00 00 06 01 06 00 1A 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 1B 00 01",
      close:"00 01 00 00 00 06 01 06 00 1B 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 1C 00 01",
      close:"00 01 00 00 00 06 01 06 00 1C 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 1D 00 01",
      close:"00 01 00 00 00 06 01 06 00 1D 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 1E 00 01",
      close:"00 01 00 00 00 06 01 06 00 1E 00 00",
    },
    {
      open:"00 01 00 00 00 06 01 06 00 1F 00 01",
      close:"00 01 00 00 00 06 01 06 00 1F 00 00",
    },
  ],

  sortAsc :function(o) {
    var n = [];
    for (var k in o) n.push(k);
    n.sort();
    for (var i = 0, str = ''; i < n.length; i++) {
        var v = o[n[i]];
        if (typeof v == 'string') {
          if (n[i].indexOf("TIME") != -1) {
            v=v.replace("T", "")
                }
          v = v.replace(/\s+/g, "")
        }
          
        if (v) {
          if ({}.toString.call(v) == '[object Object]') {
            //v = this.sortAsc(v);
            //v = JSON.stringify(v).replace(/:/g,'=');
            v = JSON.stringify(v)
          }
          else if ({}.toString.call(v) == '[object Array]') {
            //v = JSON.stringify(v).replace(/:/g,'=');
            v = JSON.stringify(v)
          }
        }
        str += n[i] + v;
    }
    ////console.log(str)
    return str;
  },
  requestDataEncrypt:function (data, token,sign) {
    var newData = {
      TIMESTAMP: new Date().getTime(),
      TOKEN: token,
      DATA: data,
      DATASIGN: sign,
    }
    return newData
  },
  GetQueryString:function(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  PH : document.body.clientHeight,
  PW : document.body.clientWidth,
  getCoordinateByStr(str){
      var arr=[]
      if(str.indexOf("MULTIPOLYGON")!= -1){
        str=str.replace("MULTIPOLYGON(","")
        str= str.substring(0,str.length-1)
        while(str.indexOf("((")!= -1){
          str=str.replace("((","")
        }
        while(str.indexOf(")),")!= -1){
          str=str.replace(")),","|")
        }
        str= str.substring(0,str.length-2)
        var b=str.split("|")
        if(b.length>0){
          for(var j=0;j<b.length;j++){
            var p2=b[j].split(",")
            var dian=[]
            for(var k=0;k<p2.length;k++){
              var  o2=p2[k].split(" ")
              dian.push([parseFloat(o2[0]),parseFloat(o2[1])])
            }
            arr.push(dian)
          }
        }
      }else if(str.indexOf("POLYGON")!= -1){
        str=str.replace("POLYGON((","")
        str= str.substring(0,str.length-2)
        var a=str.split(",")
        if(a.length>0){
          for(var i=0;i<a.length;i++){
            var p1=a[i].split(" ")
            arr.push([parseFloat(p1[0]),parseFloat(p1[1])])
          }
        }
        arr=[arr]
      } 
      return arr
  },

  getDateStrByDate(date){
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var hour = date.getHours();
    var minutes = date.getMinutes() ;
    var seconds = date.getSeconds();
    var strDate = date.getDate();
    
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }

    if (hour >= 0 && hour <= 9) {
      hour = "0" + hour;
    }
    if (minutes >= 0 && minutes <= 9) {
      minutes = "0" + minutes;
    }
    if (seconds >= 0 && seconds <= 9) {
      seconds = "0" + seconds;
    }
    return year + "-" + month + "-" + strDate+" "+hour+":" +minutes+":" +seconds;
  },
  getDateMinStrByDate(date){
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    return year + "-" + month + "-" + strDate;
  },
  compareDate(date1,date2){
    var result = true;
    if(date1.getFullYear() < date2.getFullYear()){
        result = false;
    }else{
      if(date1.getMonth() < date2.getMonth()){
          result = false;
      }else{
        if(date1.getDate() < date2.getDate()){
          result = false;
        }
      } 
    }
    return result;
  },
  sortByPhaseName(list){
    if(list && list.length > 0){
        Array.prototype.sort.call(list,function(a,b){
            var name1 = a.name.replace('相','');
            var name2 = b.name.replace('相','');
            return name1.charCodeAt(0) - name2.charCodeAt(0);
        })
    }
  },
  removeDuplicateObj (arr,key){
    var nl=[]
    for(var i=0;i<arr.length;i++){
      var d=arr[i]
      var has=false
      for(var j=0;j<nl.length;j++){
        if(nl[j][key] == d[key]){
          has=true;
          break;
        }
      }
      if(!has){
        nl.push(d)
      }
    }
    return nl;
  },
  repeatArrayList(list,column){
    var nl=[]
    for(var i=0;i<list.length;i++){
       var has=false
        for(var j=0;j<nl.length;j++){
           if(list[i][column] == nl[j][column]){
              has=true
              break;
           }
        }
       if(!has){
        nl.push(list[i])
       }
    }
    return nl
  },
}
export default comjs