<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">物料管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="zwm" placeholder="请输入中文名" class="iw" ></el-input>
                <el-input v-model="ywm" placeholder="请输入英文名" class="iw" ></el-input>
                <el-input v-model="dm" placeholder="请输入代码" class="iw" ></el-input>
                <el-input v-model="ghs" placeholder="请输入供货商" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                
                <!-- <el-button type="success" @click="addPost('1')" >添加物料</el-button> -->
                <el-button type="success" @click="goodsIn" v-if="ckid!=''" class="fr">物料入库</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="Name" label="图片">
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.Files.length>0"
                                style="width: 20px; height: 20px;margin-top:3px;"
                                :src="scope.row.Files[0].Path" 
                                :preview-src-list="[scope.row.Files[0].Path]">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Name" label="中文名"></el-table-column>
                    <el-table-column prop="EnglishName" label="英文名"></el-table-column>
                    <el-table-column prop="Code" label="代码"></el-table-column>
                    <el-table-column prop="Standards" label="规格"></el-table-column>
                    <el-table-column prop="Unit" label="计量单位"></el-table-column>
                    <el-table-column prop="Supplier" label="供货商"></el-table-column>
                    <el-table-column prop="InWareHouseCount" label="库存数量">
                        <template slot-scope="scope">
                            <span v-if="scope.row.InWareHouseCount">{{scope.row.InWareHouseCount}}</span>
                            <span v-else>0</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="300">
                        <template slot-scope="scope">
                            <el-button @click="thresholdPost(scope.row)" type="text" size="small" style="color:#67C23A">阈值设置</el-button>
                            <!-- <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button> -->
                            <el-button @click="logPost(scope.row)" type="text" size="small" style="color:#E6A23C">出入库记录</el-button>
                            <el-button @click="InOutPost(scope.row)" type="text" size="small" style="color:#409EFF" v-if="scope.row.InWareHouseCount">库存查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="中文名" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="英文名" prop="englishname">
                    <el-input v-model="postInfo.englishname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="代码" prop="code">
                    <el-input v-model="postInfo.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="规格" prop="standards">
                    <el-input v-model="postInfo.standards" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="计量单位" prop="unit">
                    <el-input v-model="postInfo.unit" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="供货商" prop="supplier">
                    <el-input v-model="postInfo.supplier" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFunD"
                            :before-upload="beforeAvatarUploadD">
                            <img v-if="imageUrlD" :src="imageUrlD" class="avatar">
                            <el-button v-else type="primary" size="mini">添加图标</el-button>
                        </el-upload>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="物料库存查看" :visible.sync="storeModal" width="1200px" :close-on-click-modal="false" top="30px" @close="storeModalClose">
                <div style="width:100%;overflow:hidden;margin-bottom:20px;" v-if="lightList.length>0">
                    <el-button type="danger" @click="closeColumnLight" class="fr">关闭全部指示灯</el-button>
                    <el-button type="success" @click="openColumnLight" class="fr" style="margin-right:20px;">开启货架指示灯</el-button>
                </div> 
                <el-table :data="storeList" stripe style="width: 100%" :height="dialogMaxHeigh"  @selection-change="handleSelectionInPosition"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="GoodName" label="货名"></el-table-column>
                    <el-table-column prop="ShelvingCode" label="货架号"></el-table-column>
                    <el-table-column prop="ShelvingRowIndex" label="行号"></el-table-column>
                    <el-table-column prop="ShelvingRowIndex" label="列号"></el-table-column>
                    <el-table-column prop="Index" label="货格号"></el-table-column>
                    <el-table-column prop="GoodCount" label="存放数量"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="inPost(scope.row)" type="text" size="small" style="color:#67C23A" v-if="ckid!=''">入库</el-button>
                            <el-button @click="movePost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="ckid!=''">调拨</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndexS" :page-size="pageSizeS" :total="TotalS" class="pg" @current-change="PGChangeS"></el-pagination>
                <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
        <el-dialog title="物料入库" :visible.sync="goodsInModal" width="1000px" :close-on-click-modal="false" top="100px" @close="searchPost">
            <el-form :model="goodsInInfo" :rules="goodsInrules"  ref="inForm" label-width="100px" class="demo-uForm">
                <el-form-item label="选择物料" prop="goodSnowID">
                    <el-input v-model="searchGoodsStr" autocomplete="off"  placeholder="输入物料名称或代码搜索，连接扫码枪后需将光标停留此处后才能生效" clearable style="width:100%;"  @keyup.enter.native="getGoodsList" ref="outGoodsCode"></el-input>
                    <div v-if="nowGoodsSelect.length>0" style="margin-top:12px;">
                        <el-select v-model="goodsInInfo.goodSnowID" placeholder="请选择物料"  filterable style="width:100%;" @change="setGoodsNameById">
                            <el-option v-for="item in nowGoodsSelect" :key="item.SnowID" :label="item.Name+'('+item.Code+')'" :value="item.SnowID" ></el-option>
                        </el-select>
                    </div>
                    <div v-else style="margin-top:12px;">
                        <p style="font-size:12px;color:#adadad;">
                            暂未查询到物料， 请联系后台管理员添加
                            <!-- <el-button @click="addPost('2')" type="text" size="small" style="color:#67C23A">立即添加</el-button> -->
                        </p>
                    </div>
                </el-form-item>
                <el-form-item label="存放位置" prop="shelvingSnowID">
                    <span>货架号：</span>
                    <el-select v-model="goodsInInfo.shelvingSnowID" placeholder="请选择货架"  filterable  style="width:100px;margin-right:12px;" @change="setRackBox">
                        <el-option v-for="item in rackList" :key="item.SnowID" :label="item.Code" :value="item.SnowID" ></el-option>
                    </el-select>
                    <span>行号：</span>
                    <el-input-number v-model="rackBox.h.value" controls-position="right"  :precision="0" :step="1" :min="1" :max="rackBox.h.max" style="width:140px;margin-right:12px;"   @change="rackBoxChangeH"></el-input-number>
                    <span>列号：</span>
                    <el-input-number v-model="rackBox.l.value" controls-position="right"  :precision="0" :step="1" :min="1" :max="rackBox.l.max" style="width:140px;margin-right:12px;" @change="rackBoxChangeL"></el-input-number>
                    <span>货格号：</span>
                    <el-input-number v-model="rackBox.g.value" controls-position="right"  :precision="0" :step="1" :min="1" :max="rackBox.g.max" style="width:140px;margin-right:12px;" @change="rackBoxChangeG"></el-input-number>
                </el-form-item>
                <el-form-item label="入库数量" prop="goodCount">
                    <el-input v-model="goodsInInfo.goodCount" autocomplete="off"  min="1"></el-input>
                </el-form-item>
                <el-form-item label="入库单价" prop="oneMoney">
                    <el-input v-model="goodsInInfo.oneMoney" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="货物来源">
                    <el-input v-model="goodsInInfo.source" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="goodsInInfo.bz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitInPostForm()">提交</el-button>
                    <el-button @click="goodsInModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="入库信息确认" :visible.sync="showGoodsInAffirmModal" width="400px" :close-on-click-modal="false">
            <div>
                <p>入库商品：{{goodsInAffrimName}}</p>
                <p>存放位置：{{goodsInInfo.gridCode}}</p>
                <p>入库数量：{{goodsInInfo.goodCount}}</p>
                <p>入库单价：{{goodsInInfo.oneMoney}}</p>
                <p>货物来源：{{goodsInInfo.source}}</p>
                <p>备　　注：{{goodsInInfo.bz}}</p>
                <p>
                <el-checkbox v-model="showGoodsInMsg">下次入库前继续提醒</el-checkbox>
                </p>
            </div>
            <div style="margin-top:30px;">
                <el-button type="primary" @click="sendGoodsIn()">确认提交入库</el-button>
                <el-button @click="showGoodsInAffirmModal = false">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="goodsThresholdModal" width="800px" :close-on-click-modal="false">
            <p style="font-size:14px;color:#666666;margin-bottom:20px;" v-if="saveItem">请设置<span style="color:#E6A23C;font-weight: bold;"> {{saveItem.Name}} </span>在仓库的库存阈值，当物料出库后低于此阈值时会提醒库管及时补货(0视为不提醒)</p>
            <div style="overflow:hidden;margin-bottom:20px;" v-for="(item,index) in storeThresholdList" :key="item.SnowID">
                <p style="font-size:14px;font-weight: bold;color:#F56C6C;margin-bottom:5px;">{{item.Name}}</p>
                <el-input v-model="item.threshold" autocomplete="off" placeholder="请输入阈值" style="width:80%;margin-right:20px;"></el-input>
                <el-button type="primary" @click="submitThresholdForm(index)">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="moveStr" :visible.sync="moveModal" width="1000px" :close-on-click-modal="false" top="100px" @close="searchPost">
            <el-form :model="goodsMoveInfo" :rules="goodsMoverules"  ref="moveForm" label-width="100px" class="demo-uForm">
                <el-form-item label="调拨物品">
                    <p v-if="nowMove">{{nowMove.GoodName}}</p>
                </el-form-item>
                <el-form-item label="当前位置">
                    <!-- <p v-if="nowMove">{{nowMove.ShelvingCode}}货架-{{nowMove.ShelvingRowIndex}}行-{{nowMove.ShelvingColumnIndex}}列-{{nowMove.Index}}货格</p> -->
                    <p v-if="nowMove">{{baseBoxCode}}</p>
                </el-form-item>
                <el-form-item label="当前数量">
                    <p v-if="nowMove">{{nowMove.GoodCount}}</p>
                </el-form-item>
                <el-form-item label="调入位置" prop="shelvingSnowID">
                    <span>货架号：</span>
                    <el-select v-model="goodsMoveInfo.shelvingSnowID" placeholder="请选择货架"  filterable  style="width:100px;margin-right:12px;" @change="setRackBox">
                        <el-option v-for="item in rackList" :key="item.SnowID" :label="item.Code" :value="item.SnowID" ></el-option>
                    </el-select>
                    <span>行号：</span>
                    <el-input-number v-model="rackBox.h.value" controls-position="right"  :precision="0" :step="1" :min="1" :max="rackBox.h.max" style="width:140px;margin-right:12px;"   @change="rackBoxChangeH"></el-input-number>
                    <span>列号：</span>
                    <el-input-number v-model="rackBox.l.value" controls-position="right"  :precision="0" :step="1" :min="1" :max="rackBox.l.max" style="width:140px;margin-right:12px;" @change="rackBoxChangeL"></el-input-number>
                    <span>货格号：</span>
                    <el-input-number v-model="rackBox.g.value" controls-position="right"  :precision="0" :step="1" :min="1" :max="rackBox.g.max" style="width:140px;margin-right:12px;" @change="rackBoxChangeG"></el-input-number>
                </el-form-item>
                <el-form-item label="调入数量" prop="goodCount">
                    <el-input-number v-if="nowMove" v-model="goodsMoveInfo.goodCount" controls-position="right"  :precision="0" :step="1" :min="1" :max="nowMove.GoodCount" style="width:100%;"></el-input-number>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="goodsMoveInfo.bz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitMovePostForm()">提交</el-button>
                    <el-button @click="moveModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="出入库记录查看" :visible.sync="logModal" width="1200px" :close-on-click-modal="false" top="30px" @close="storeModalClose">
            <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                <el-select v-model="logType" placeholder="出入库类型"   class="iww" >
                        <el-option label="入库记录" value="1" ></el-option>
                        <el-option label="调拨记录" value="3" ></el-option>
                        <el-option label="出库记录" value="4" ></el-option>
                </el-select>
                <el-date-picker v-model="logTimes" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" class="iwss"></el-date-picker>
                <el-button type="primary" @click="searchPostL">查询</el-button>
            </div> 
            <el-table :data="logList" stripe style="width: 100%" :height="dialogMaxHeigh" > 
                <el-table-column prop="WAREHOUSENAME" label="仓库"></el-table-column>
                <el-table-column prop="GoodName" label="货名"></el-table-column>
                <el-table-column prop="GoodCount" label="入库数量" v-if="logType=='1'"></el-table-column>
                <el-table-column prop="ONEMONEY" label="入库单价" v-if="logType=='1'"></el-table-column>
                <el-table-column prop="SAVECODE" label="入库货格" v-if="logType=='1'"></el-table-column>
                <el-table-column prop="CreateTime" label="入库时间" v-if="logType=='1'" width="165"></el-table-column>
                <el-table-column prop="SOURCE" label="货物来源" v-if="logType=='1'"></el-table-column>

                <el-table-column prop="GoodCount" label="调入数量" v-if="logType=='3'"></el-table-column>
                <el-table-column prop="SAVECODE" label="调入货格" v-if="logType=='3'"></el-table-column>
                <el-table-column prop="OLDSAVECODE" label="被调货格" v-if="logType=='3'"></el-table-column>

                <el-table-column prop="GoodCount" label="出库数量" v-if="logType=='4'"></el-table-column>
                <el-table-column prop="SAVECODE" label="出库货格" v-if="logType=='4'"></el-table-column>
                <el-table-column prop="Target" label="出库去向" v-if="logType=='4'"></el-table-column>

                <el-table-column prop="BZ" label="备注"></el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndexL" :page-size="pageSizeL" :total="TotalL" class="pg" @current-change="PGChangeL"></el-pagination>
            <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "UnitManage",

  data() {
    var name = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入中文名"));
      } else {
        callback();
      }
    };
    var englishname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入英文名"));
      } else {
        callback();
      }
    };
    var code = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入代码"));
      } else {
        callback();
      }
    };
    var standards = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入规格"));
      } else {
        callback();
      }
    };
    var unit = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入计量单位"));
      } else {
        callback();
      }
    };
    var supplier = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入供货商"));
      } else {
        callback();
      }
    };  
    
    var position=(rule, value, callback) => {
      if (value.length==0) {
        callback(new Error("请选择存放位置"));
      } else {
        callback();
      }
    };  

    var mposition=(rule, value, callback) => {
      if (value.length==0) {
        callback(new Error("请选择调拨后存放位置"));
      }else if(value[4] === this.saveItem.SnowID){
        callback(new Error("请选择调拨前后位置不能一样"));
      } else {
        callback();
      }
    };  

    var goodSnowID= (rule, value, callback) => {
        if (value.length==0) {
            callback(new Error("请选择商品"));
        } else {
            callback();
        }
    };

    var shelvingSnowID= (rule, value, callback) => {
        if (value.length==0) {
            callback(new Error("请选择存放位置"));
        } else {
            callback();
        }
    };


    var count= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };

    var mgoodCount= (rule, value, callback) => {
      if (value === '') {
            callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else if(value > this.saveItem.GoodCount){
            callback(new Error('调拨数量不能大于库存数量'));
        }else {
          callback();
        }
    };

    var ogoodCount= (rule, value, callback) => {
      if (value === '') {
            callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else if(value > this.saveItem.GoodCount){
            callback(new Error('出库数量不能大于库存数量'));
        }else {
          callback();
        }
    };

    var oneMoney=(rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入入库单价'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('入库单价只能是数字'));
        }else if(value<0){
            callback(new Error('入库单价必须大于0'));
        }else {
          callback();
        }
    };

    var dbyy = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入调拨原因"));
      } else {
        callback();
      }
    };  

    var target = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入出库用途"));
      } else {
        callback();
      }
    }; 
    return {
        sysType:"",
        ckid:"",
        dialogMaxHeigh:0,

        canDo:true,
        showPagination:false,
        zwm:"",
        ywm:"",
        dm:"",
        ghs:"",

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,

        addFrom:"",
        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            name: [{ validator: name, trigger: "blur" }],
            englishname: [{ validator: englishname, trigger: "blur" }],
            code: [{ validator: code, trigger: "blur" }],
            standards: [{ validator: standards, trigger: "blur" }],
            unit: [{ validator: unit, trigger: "blur" }],
            supplier: [{ validator: supplier, trigger: "blur" }],
        },

        imageFile:null,
        imageUrlD:"",



        storeModal:false,
        nowGoods:null,
        storeList:[],
        pageIndexS:1,
        pageSizeS:10,
        TotalS:0,
        selectColumn:[],
        

        lightList:[],

        rackList:[],
        rackInfo:[],
        rackBox:{
            h:{max:0,value:1,},
            l:{max:0,value:1,},
            g:{max:0,value:1,},
        },

        searchGoodsStr:"",
        nowGoodsSelect:[],
        goodsInModal:false,
        goodsInInfo:{},
        goodsInrules:{
            goodSnowID: [{ validator: goodSnowID, trigger: "blur" }],
            shelvingSnowID: [{ validator: shelvingSnowID, trigger: "blur" }],
            goodCount: [{ validator: count, trigger: "blur" }],
            oneMoney: [{ validator: oneMoney, trigger: "blur" }],
        },
        showGoodsInMsg:true,
        showGoodsInAffirmModal:false,
        goodsInAffrimName:"",

        nowMove:null,
        moveModal:false,
        moveStr:"",
        baseBoxCode:"",
        goodsMoveInfo:{},
        goodsMoverules:{
            shelvingSnowID: [{ validator: shelvingSnowID, trigger: "blur" }],
            goodCount: [{ validator: count, trigger: "blur" }],
        },


        saveItem:null,


        storeThresholdList:[],
        goodsThresholdModal:false,


        logModal:false,
        logList:[],
        pageIndexL:1,
        pageSizeL:10,
        TotalL:0,
        logType:"1",
        logTimes:[],
    };
  },
  mounted() {
    this.sysType=this.$store.state.sysType
    this.ckid=this.$store.state.ckid
    this.dialogMaxHeigh = h -300
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.zwm=getQueryData.name
        this.ywm=getQueryData.englishName
        this.dm=getQueryData.code
        this.ghs=getQueryData.supplier
    }
    this.loadItem=null
    this.getPostList()
    
    if(this.ckid!=''){
        this.getAllStoreList()
        this.getRackList()
    }
    this.getLightList()
  },
  methods: {
    storeModalClose(){
        this.nowGoods=null  
    },
    getAllStoreList(){
        var params={
            snowID:this.ckid
        }
        this.$http.post("WareHouse/GetDataList", params)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    var d=list[0]
                    d.threshold=0
                    d.InCount=0
                    this.storeThresholdList.push(d)
                } 
            })
    },
    getLightList(){
        var params={
            wareHouseSnowID:this.ckid,
        }
        this.$http.post("Relay/GetDataList", params)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.lightList =response.data.DATA
                } 
            })
    },
    getRackList(){
        var params={
            wareHouseSnowID:this.ckid,
        }
        this.$http.post("Shelving/GetDataList", params)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.rackList =response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.zwm,
            englishName:this.ywm,
            code:this.dm,
            supplier:this.ghs,
            wareHouseSnowID:this.ckid,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Good/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },
    addPost(from){
        this.addFrom=from
        this.postInfo={
            name:"",
            englishname:"",
            code:"",
            standards:"",
            unit:"",
            supplier:"",
        }
        this.imageFile=null
        this.imageUrlD=""
        this.doTypeStr="添加物料"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Good/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    if(this.imageFile){
                        this.addImg(response.data.DATA)
                    }
                    this.getPostList()
                    this.postModal=false
                    if(this.addFrom=="2"){
                        this.searchGoodsStr=this.postInfo.code
                        this.getGoodsList()
                    }
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    editPost(item){
        this.imageFile=null
        this.imageUrlD=""
        this.postInfo={
            snowID:item.SnowID,
            name:item.Name,
            englishname:item.EnglishName,
            code:item.Code,
            standards:item.Standards,
            unit:item.Unit,
            supplier:item.Supplier,
        }
        this.doTypeStr="修改物料"
        this.doType="2"
        this.postModal=true
        if(item.Files.length>0){
            this.imageUrlD=item.Files[0].Path
        }
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Good/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    if(this.imageFile){
                        this.addImg(this.postInfo.snowID)
                    }
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },


    beforeAvatarUploadD(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrlD = _URL.createObjectURL(file);
            this.imageFile=file
        }
        return isJPG && isLt2M;
    },
    uploadFunD(file){
        //console.log(file)
    },

    
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除物料：'+item.Name+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    addImg(id){
        var fd = new FormData();
        fd.append("file", this.imageFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=1&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Good/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },

    /**
     * 查看库存
     */
    InOutPost(item){
        this.nowGoods=item
        this.selectColumn=[]
        this.pageIndexS=1
        this.getGoodsStores()
        this.storeModal=true
    },
    getGoodsStores(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndexS,
            pageSize:this.pageSizeS,
            goodSnowID:this.nowGoods.SnowID,
            WareHouseSnowID:this.ckid,
        }
        this.$http.post("ColumnGrid/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.storeList=response.data.DATA
                    this.TotalS=response.data.TOTALCOUNT
                } else {
                    this.storeList=[]
                    this.TotalS=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.storeList=[]
                this.TotalS=0
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                }
            });
    },
    searchPostS(){
        this.pageIndexS=1
        this.getGoodsStores()
    },
    PGChangeS(e){
        this.pageIndexS=e
        this.getGoodsStores()
    },
    /**
     * 开启货架指示灯
     */
    openColumnLight(){
        if(this.selectColumn.length>0){
            var obj={}
            var c=0
            for(var i=0;i<this.selectColumn.length;i++){
                var d=this.selectColumn[i]
                if(d.Address && d.RelayNO){
                    if(obj[d.Address] == undefined){
                        obj[d.Address]=[]
                    }
                    obj[d.Address].push(d.RelayNO)
                    c++
                }
            }
            if(c>0){
                this.setLightCommands(obj)
            }else{
                this.$message.error("所选货架暂未绑定指示灯！")
            }
        }else{
            this.$message.error("请至少选择一个位置")
        }
    },
    setLightCommands(obj){
        var commands=[]
        //遍历继电器
        for(var j=0;j<this.lightList.length;j++){
            let litem=this.lightList[j]
            let command='zq '+litem.Address+' set'
            let objList=[]
            if(obj[litem.Address] != undefined && obj[litem.Address].length>0){
                objList=obj[litem.Address]
            }
            //遍历继电器的每个灯
            for(var k=1;k<=litem.LightCount;k++){
                let m=' 0'
                //判断灯的开启状态
                for(var h=0;h<objList.length;h++){
                    if(objList[h] == k){
                        m=' 1'
                        break;
                    }
                }
                command+=m
            }
            command+=' qz'
            let citem={
                RelayAddr:litem.Address,
                Command:command,
            }
            commands.push(citem)
        }
        this.openRelayLights(commands)
    },
    closeColumnLight(){
        this.$confirm('确定关闭所有指示灯？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.setLightCommands({})
        })
    },
    openRelayLights(list){
        this.$http.post("Relay/SendCommand", list)
            .then((response) => {
                this.$message.success("命令发送完成！")
            })
            .catch((error) => {
                this.$message.error("请求出错")
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },

    handleSelectionInPosition(e){
        this.selectColumn=e
    },

    //库存内入库
    inPost(item){
        this.baseBoxCode=""
        this.searchGoodsStr=""
        this.getGoodsList()
        this.goodsInModal=true
        this.goodsInInfo={
            goodSnowID:item.GoodSnowID,
            shelvingSnowID:item.ShelvingSnowID,
            gridCode:"",
            goodCount:"",
            oneMoney:"",
            source:"",
            bz:"",
        }
        if(this.rackList.length>0){
            this.goodsInInfo.shelvingSnowID=this.rackList[0].SnowID
        }
        //rowValue,colValue,boxValue
        this.setRackBox(item.ShelvingSnowID,item.ShelvingRowIndex,item.ShelvingColumnIndex,item.Index)
    },

    /**
     * 入库
     */
    goodsIn(){
        this.baseBoxCode=""
        this.searchGoodsStr=""
        this.getGoodsList()
        this.goodsInModal=true
        this.goodsInInfo={
            goodSnowID:"",
            shelvingSnowID:"",
            gridCode:"",
            goodCount:"",
            oneMoney:"",
            source:"",
            bz:"",
        }
        if(this.rackList.length>0){
            this.goodsInInfo.shelvingSnowID=this.rackList[0].SnowID
            this.setRackBox(this.goodsInInfo.shelvingSnowID)
        }
        
    },
    setGoodsNameById(id){
        this.goodsInAffrimName=""
        if(id!=""){
            for(var i=0;i<this.nowGoodsSelect.length;i++){
                if(this.nowGoodsSelect[i].SnowID == id){
                    this.goodsInAffrimName=this.nowGoodsSelect[i].Name+"("+this.nowGoodsSelect[i].Code+")"
                    break;
                }
            }
        }
    },
    getGoodsList(){
        this.nowGoodsSelect=[]
        if(this.searchGoodsStr==""){
            this.$http.post("Good/GetDataList", {})
            .then((response) => {
                    this.nowGoodsSelect=response.data.DATA
                    if(this.nowGoodsSelect.length>0){
                        this.goodsInInfo.goodSnowID=this.nowGoodsSelect[0].SnowID
                        this.goodsInAffrimName=this.nowGoodsSelect[0].Name+"("+this.nowGoodsSelect[0].Code+")"
                    }else{
                            this.goodsInInfo.goodSnowID=""
                            this.goodsInAffrimName=""
                        }
                })
        }else{
            this.$http.post("Good/GetDataList", {name:this.searchGoodsStr})
                .then((responseA) => {
                    var listA=responseA.data.DATA
                    for(var i=0;i<listA.length;i++){
                        var f=this.flagInfoIsHave(this.nowGoodsSelect,listA[i])
                        if(!f){
                            this.nowGoodsSelect.push(listA[i])
                        }
                    }

                    this.$http.post("Good/GetDataList", {AllCode:this.searchGoodsStr})
                    .then((responseB) => {
                        var listB=responseB.data.DATA
                        for(var i=0;i<listB.length;i++){
                            var f=this.flagInfoIsHave(this.nowGoodsSelect,listB[i])
                            if(!f){
                                this.nowGoodsSelect.push(listB[i])
                            }
                        }
                        if(this.nowGoodsSelect.length>0){
                            this.goodsInInfo.goodSnowID=this.nowGoodsSelect[0].SnowID
                            this.goodsInAffrimName=this.nowGoodsSelect[0].Name+"("+this.nowGoodsSelect[0].Code+")"
                        }else{
                            this.goodsInInfo.goodSnowID=""
                            this.goodsInAffrimName=""
                        }
                    })
                })
        }
    },
    flagInfoIsHave(list,info){
        var have=false
        for(var i=0;i<list.length;i++){
            if(list[i].SnowID == info.SnowID){
                have=true
                break;
            }
        }
        return have
    },
    setRackBox(rackValue,rowValue,colValue,boxValue){
        this.rackBox={
            rack:null,
            h:{max:0,value:0,},
            l:{max:0,value:0,},
            g:{max:0,value:0,},
            boxCode:"",
        }
        this.rackInfo=[]
        var rackCode=""
        if(rackValue!=""){
            for(var i=0;i<this.rackList.length;i++){
                if(this.rackList[i].SnowID == rackValue){
                    this.rackInfo=JSON.parse(this.rackList[i].Info)
                    this.rackBox.rack=this.rackList[i]
                    break;
                }
            }
            if(this.rackInfo.length>0){
                this.rackBox.h.max=this.rackInfo.length
                if(rowValue!=undefined){
                    this.rackBox.h.value=rowValue
                }else{
                    this.rackBox.h.value=1
                }
                if(this.rackInfo[0].length>0){
                    this.rackBox.l.max=this.rackInfo[0].length
                    if(colValue != undefined){
                        this.rackBox.l.value=colValue
                    }else{
                        this.rackBox.l.value=1
                    }
                    if(this.rackInfo[0][0]>0){
                        this.rackBox.g.max=this.rackInfo[0][0]
                        if(boxValue!=undefined){
                            this.rackBox.g.value=boxValue
                        }else{
                            this.rackBox.g.value=1
                        }
                    }
                }
                this.rackBox.boxCode=this.rackBox.rack.Code+"-"+this.rackBox.h.value+"-"+this.rackBox.l.value+"-"+this.rackBox.g.value
            }
        }
    },  
    rackBoxChangeH(){
        var hh=(this.rackBox.h.value) -1
        if(this.rackInfo[hh].length>0){
            this.rackBox.l.max=this.rackInfo[hh].length
            this.rackBox.l.value=1
            if(this.rackInfo[hh][0]>0){
                this.rackBox.g.max=this.rackInfo[hh][0]
                this.rackBox.g.value=1
            }
        }
        this.rackBoxChangeG()
    },
    rackBoxChangeL(){
        var hh=(this.rackBox.h.value) -1
        var lh=(this.rackBox.l.value) -1
        if(this.rackInfo[hh][lh]>0){
            this.rackBox.g.max=this.rackInfo[hh][lh]
            this.rackBox.g.value=1
        }
        this.rackBoxChangeG()
    },
    rackBoxChangeG(){
        var boxCode=this.rackBox.rack.Code+"-"+this.rackBox.h.value+"-"+this.rackBox.l.value+"-"+this.rackBox.g.value
        this.rackBox.boxCode=boxCode
    },
    submitInPostForm(){
        if (this.canDo) {
            this.$refs.inForm.validate((valid) => {
                if (valid) {
                    this.goodsInInfo.gridCode=this.rackBox.rack.Code+"-"+this.rackBox.h.value+"-"+this.rackBox.l.value+"-"+this.rackBox.g.value
                    this.goodsInInfo.goodCount = parseInt(this.goodsInInfo.goodCount)
                    this.goodsInInfo.oneMoney = parseInt(this.goodsInInfo.oneMoney)
                    if(this.showGoodsInMsg){
                        this.showGoodsInAffirmModal=true
                    }else{
                        this.sendGoodsIn()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    sendGoodsIn(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("GoodIn/InWareHouse", this.goodsInInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已入库")
                    this.goodsInInfo.goodCount=0
                    this.goodsInInfo.oneMoney=0
                    this.showGoodsInAffirmModal=false
                    setTimeout(() => {
                        this.$refs.outGoodsCode.focus()   
                    }, 200);
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },
    getRackCodeById(id){
        var code=""
        for(var i=0;i<this.rackList.length;i++){
            if(this.rackList[i].SnowID == id){
                code=this.rackList[i].Code
                break;
            }
        }
        return code
    },

    /**
     * 调拨
     */
    movePost(item){
        this.nowMove=item
        this.moveStr=item.GoodName+"库存调拨"
        this.baseBoxCode=item.ShelvingCode+"-"+item.ShelvingRowIndex+"-"+item.ShelvingColumnIndex+"-"+item.Index
        this.moveModal=true
        this.goodsMoveInfo={
            oldShelvingSnowID:item.ShelvingSnowID,
            oldGridCode:this.baseBoxCode,
            shelvingSnowID:item.ShelvingSnowID,
            gridCode:"",
            goodCount:1,
            bz:"",
        }
        this.setRackBox(item.ShelvingSnowID)
        
    },
    submitMovePostForm(){
        if (this.canDo) {
            this.$refs.moveForm.validate((valid) => {
                if (valid) {
                    this.goodsMoveInfo.gridCode=this.rackBox.rack.Code+"-"+this.rackBox.h.value+"-"+this.rackBox.l.value+"-"+this.rackBox.g.value
                    this.goodsMoveInfo.goodCount = parseInt(this.goodsMoveInfo.goodCount)
                    if(this.goodsMoveInfo.gridCode == this.baseBoxCode){
                        this.$message.error("调拨前后位置不能一样！")
                    }else{
                        this.sendGoodsMove()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    sendGoodsMove(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("GoodIn/WareHouseDispatch", this.goodsMoveInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已调拨")
                    this.moveModal=false
                    this.getGoodsStores()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },

    /**
     * 阈值设置
     */
    thresholdPost(item){
        if(this.canDo){
            this.saveItem=item
            this.doTypeStr=item.Name+"-阈值设置"
            this.getGoodsThreshold()
        }
    },
    getGoodsThreshold(){
        var params={
            goodSnowID:this.saveItem.SnowID,
        }
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("WarningSetting/GetDataList", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.setGoodsThreshold(response.data.DATA)
                }else{
                    this.setGoodsThreshold([])
                }
            })
            .catch((error) => {
                this.setGoodsThreshold([])
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    setGoodsThreshold(list){
        var obj={}
        for(var i=0;i<this.storeThresholdList.length;i++){
            var d=this.storeThresholdList[i]
            d.threshold=0
            obj[d.SnowID]=d
        }

        for(var j=0;j<list.length;j++){
            var t=list[j]
            obj[t.WareHouseSnowID].threshold=t.GoodCount
            obj[t.WareHouseSnowID].InCount=t.WareHouseCount
        }

        var nl=[]
        for(var item in obj){
            nl.push(obj[item])
        }
        this.storeThresholdList=nl
        this.goodsThresholdModal=true
    },
    submitThresholdForm(index){
        var num=this.storeThresholdList[index].threshold
        if(typeof num != 'number' && isNaN(num)){
            this.$message.error("阈值只能是数字")
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(num)){
            this.$message.error("只能输入大于等于0的整数")
        }else{
            var params={
                wareHouseSnowID:this.storeThresholdList[index].SnowID,
                goodSnowID:this.saveItem.SnowID,
                goodCount:num,
            }
            this.sendThresholdSave(params)
        }
    },
    sendThresholdSave(params){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("WarningSetting/InsertOrUpdateData", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("设置成功！")
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    /**
     * 出入库记录
     */
    logPost(item){
        this.nowGoods=item
        this.searchPostL()
        this.logModal=true
    },
    getGoodsLogList(){
        this.showLoading("请稍后")
        var st=''
        var et=''
        if(this.logTimes && this.logTimes.length==2){
            st=this.comjs.getDateStrByDate(this.logTimes[0])
            et=this.comjs.getDateStrByDate(this.logTimes[1])
        }
        var params={
            pageIndex:this.pageIndexL,
            pageSize:this.pageSizeL,
            goodSnowID:this.nowGoods.SnowID,
            WareHouseSnowID:this.ckid,
            status:this.logType,
            startTime:st,
            endTime:et,
        }
        this.$http.post("GoodIn/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.logList=response.data.DATA
                    this.TotalS=response.data.TOTALCOUNT
                } else {
                    this.logList=[]
                    this.TotalL=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.logList=[]
                this.TotalL=0
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                }
            });
    },
    searchPostL(){
        this.pageIndexL=1
        this.getGoodsLogList()
    },
    PGChangeL(e){
        this.pageIndexL=e
        this.getGoodsLogList()
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>


</style>